import { template as template_fe1bd68577704f4493ca59d4af4e0fff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_fe1bd68577704f4493ca59d4af4e0fff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
