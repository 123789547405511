import { template as template_a383669af7354b08b5865ab84cf73038 } from "@ember/template-compiler";
const FKLabel = template_a383669af7354b08b5865ab84cf73038(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
