import { template as template_af9b2025388540adb4ec1da24f696aa2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_af9b2025388540adb4ec1da24f696aa2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
